body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.inline {
  display: flex;
}

.ant-popover-inner-content{
  white-space: nowrap;
}

body > iframe { display: none }

.styled-form .ant-form-item {
  margin-bottom: 8px;
}

.styled-form .ant-form-item-no-colon::after {
  margin: 0 !important;
}

.styled-form .ant-form-item .ant-form-item {
  margin-bottom: 0;
}

.styled-form .ant-form-item-label {
  padding: 0 !important;
  text-align: start;
}

.styled-form .mr {
  margin-right: 8px;
}